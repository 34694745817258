<template>
  <v-bottom-navigation grow fixed class="bottom-nav-bar primary" color="white">
    <!-- home -->
    <v-btn class="bottom-home" :to="{ name: 'Home' }">
      <span>{{ $t("bottom.home") }}</span>
      <v-icon>$home11</v-icon>
    </v-btn>
    <!-- coupon -->
    <v-btn v-if="isAuthenticated" @click.prevent.stop="triggerButtonClick()">
      <span>{{ $t("bottom.coupon") }}</span>
      <GiftMenuButton offset="100" />
    </v-btn>
    <v-btn v-else @click="handleLogin('/')">
      <span>{{ $t("bottom.coupon") }}</span>
      <v-icon>$vantaggi</v-icon>
    </v-btn>
    <!-- prodotti -->
    <v-btn
      @click.prevent.stop="
        $store.commit('custom/updateMenuPositionModel', 0);
        drawerLeft = !drawerLeft;
      "
    >
      <span>{{ $t("bottom.basket") }}</span>
      <v-icon>$basket</v-icon>
    </v-btn>
    <!-- user -->
    <v-btn @click.stop="handleDrawer()"
      ><span>{{ $t("bottom.user") }}</span> <v-icon>$user1</v-icon></v-btn
    >
    <!-- <v-btn
      v-if="isAuthenticated"
      @click="
        $store.commit('custom/updateMenuPositionModel', 1);
        drawerLeft = !drawerLeft;
      "
    >
      <span>{{ $t("bottom.user") }}</span>
      <v-icon>$user1</v-icon>
    </v-btn> -->
    <!-- carrello -->
    <v-btn @click="handleLogin('/cart')">
      <v-badge
        :content="totalItems"
        :value="totalItems > 0"
        :key="totalItems"
        transition="bounce"
        color="white default--text font-weight-bold"
        overlap
        offset-x="20"
        class="d-flex flex-column"
      >
        <v-icon>$cart1</v-icon>
        <span>{{ $t("bottom.cart") }}</span>
      </v-badge>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
import GiftMenuButton from "../gift/GiftMenuButton.vue";

import login from "~/mixins/login";

import { mapActions, mapGetters, mapState } from "vuex";

export default {
  mixins: [login],
  components: {
    GiftMenuButton
  },
  data() {
    return { dialog: false, benefitMenu: false };
  },
  methods: {
    ...mapActions({
      updatedDrawerLeft: "app/updatedDrawerLeft",
      updatedDrawerLinks: "app/updatedDrawerLinks"
    }),
    async handleLogin(path) {
      if (this.isAuthenticated) {
        if (path == "/profile/gifts") {
          this.dialog = true;
        } else {
          this.$router.push(path);
        }
      } else {
        let isLoggedIn = await this.doLogin();
        if (isLoggedIn) {
          if (path == "/profile/gifts") {
            this.dialog = true;
          } else {
            this.$router.push(path);
          }
        }
      }
    },
    async handleDrawer() {
      if (this.isAuthenticated) {
        this.$store.commit("custom/updateMenuPositionModel", 1);
        this.drawerLeft = !this.drawerLeft;
      } else {
        let isLoggedIn = await this.doLogin();
        if (isLoggedIn) {
          this.$store.commit("custom/updateMenuPositionModel", 1);
          this.drawerLeft = !this.drawerLeft;
        }
      }
    },
    triggerButtonClick() {
      global.EventBus.$emit("giftButtonClick");
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    }),
    ...mapState({
      storeDrawerLeft: state => state.app.drawerLeft,
      storeDrawerLinks: state => state.app.drawerLinks
    }),
    totalItems() {
      return this.$store.state?.cart?.cart?.totalItems;
    },
    drawerLeft: {
      get() {
        return this.storeDrawerLeft;
      },
      set(value) {
        this.updatedDrawerLeft(value);
      }
    },
    drawerLinks: {
      get() {
        return this.storeDrawerLinks;
      },
      set(value) {
        this.updatedDrawerLinks(value);
      }
    }
  },
  mounted() {
    global.EventBus.$on("closeBenefitsDialog", () => {
      this.dialog = false;
    });
  }
};
</script>
<style lang="scss">
.is-cordova {
  .v-main {
    padding-bottom: calc(160px + env(safe-area-inset-top)) !important;
  }
}
.keyboard-open {
  .v-main {
    padding-bottom: calc(80px + env(safe-area-inset-top)) !important;
  }
}
.platform-ios {
  .v-main {
    padding-bottom: calc(210px + env(safe-area-inset-top)) !important;
  }
}
.platform-ios.keyboard-open {
  .v-main {
    padding-bottom: calc(110px + env(safe-area-inset-top)) !important;
  }
}
.v-main__wrap {
  min-height: calc(100vh - 150px);
}
.bottom-nav-bar {
  height: $bottom-navigation-bar-height;
  position: fixed;
  bottom: 0;
  height: calc(
    #{$bottom-navigation-bar-height} + constant(safe-area-inset-top) + 20px
  ) !important;
  padding-top: constant(safe-area-inset-top);
  padding-left: constant(safe-area-inset-left);
  padding-right: constant(safe-area-inset-right);
  height: calc(
    #{$bottom-navigation-bar-height} + env(safe-area-inset-top) + 20px
  ) !important;
  padding-top: env(safe-area-inset-top);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  @media screen and (max-width: 375px) {
    .v-btn {
      padding: 0px !important;
    }
  }
}
.platform-ios {
  .bottom-nav-bar {
    //height: $bottom-navigation-bar-height-ios !important;
    position: fixed;
    bottom: 0;
    padding-top: constant(safe-area-inset-top);
    padding-left: constant(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
    max-height: calc(
      #{$bottom-navigation-bar-height-ios} + env(safe-area-inset-top) + 20px
    ) !important;
    padding-top: calc(env(safe-area-inset-top));
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    padding-bottom: calc(90px + env(safe-area-inset-bottom));
    .v-icon {
      font-size: 32px !important;
    }
  }
}
.v-bottom-navigation {
  .v-btn {
    min-width: auto !important;
    padding: 0 5px;
    .v-btn__content {
      color: $white !important;
      span {
        font-size: 11px;
        font-weight: normal;
      }
      .v-icon {
        font-size: 27px;
        line-height: 1.5;
      }
    }
  }
  .shop-badge {
    .v-badge__badge {
      background-color: #fd7e14;
      font-size: 9px;
    }
  }
}

// .is-cordova {
//   .v-application--wrap
//     ~ .v-menu__content.theme--light.v-menu__content--fixed.menuable__content__active {
//     //top: 75px !important;
//     @media #{map-get($display-breakpoints, 'xs-only')} {
//       left: 70px !important;
//     }
//     left: 160px !important;
//   }
// }
.v-bottom-navigation {
  .v-btn__loader {
    color: $white !important;
  }
  .advantages-button {
    height: 40px !important;
    .v-btn__content {
      color: $white !important;
      span {
        font-size: 11px;
        font-weight: normal;
      }
      .v-icon {
        color: $white !important;
        font-size: 27px;
        line-height: 1.5;
      }
    }
  }
  .shop-badge {
    .v-badge__badge {
      background-color: #fd7e14;
      font-size: 9px;
    }
  }
}
.keyboard-open {
  .bottom-nav-bar {
    display: none !important;
  }
}
</style>
